<template>
    <v-container>
        <v-row>
            <v-col class="col-12">
                <img src="@/assets/logo/logo.svg" />
                <h1 class="text-center">
                    ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
                </h1>
            </v-col>
            <v-col class="col-12">
                <p>
                    Η εφαρμογή «#LittleGreenFarmers» αναπτύχθηκε από την
                    Ιδιωτική Κεφαλαιουχική Εταιρία με την επωνυμία «ΑΓΡΟΤΙΚΕΣ
                    ΕΦΑΡΜΟΓΕΣ ΙΚΕ», που εδρεύει στη Θεσσαλονίκη, οδός Κορυτσάς
                    αρ. 34, με Α.Φ.Μ. 800656470. Η εταιρία δίνει ιδιαίτερη
                    σημασία στην ασφάλεια και σέβεται το απόρρητο και την
                    εμπιστευτικότητα των προσωπικών σας δεδομένων. Για το λόγο
                    αυτό, επενδύουμε χρόνο και πόρους για να προστατεύουμε την
                    ιδιωτικότητά σας, έχοντας συμπεριλάβει από την αρχή του
                    σχεδιασμού της εφαρμογής τους κανόνες ορθής πρακτικής και
                    ασφάλειας, συμμορφούμενοι απολύτως με το ισχύον εθνικό,
                    ευρωπαϊκό και διεθνές νομικό πλαίσιο και ιδίως με το Γενικό
                    Κανονισμό Προστασίας των Προσωπικών Δεδομένων 679/2016 της
                    Ευρωπαϊκής Ένωσης.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        ΣΚΟΠΟΣ ΤΗΣ ΠΑΡΟΥΣΑΣ ΠΟΛΙΤΙΚΗΣ
                    </span>
                    Επιθυμούμε να σας πληροφορήσουμε για τον τρόπο με τον οποίο
                    τα προσωπικά σας δεδομένα συλλέγονται, αποθηκεύονται,
                    χρησιμοποιούνται και διαβιβάζονται, τα μέτρα ασφάλειας που
                    λαμβάνουμε για την προστασία τους, τους λόγους και το
                    χρονικό διάστημα που αυτά αποθηκεύονται, αλλά και για το
                    είδος των προσωπικών δεδομένων που συλλέγονται. Αφορά δε,
                    κάθε πράξη ή σειρά πράξεων που πραγματοποιείται με ή χωρίς
                    τη χρήση αυτοματοποιημένων μέσων, σε προσωπικά δεδομένα ή σε
                    σύνολα προσωπικών δεδομένων, όπως η συλλογή, η καταχώριση, η
                    οργάνωση, η διάρθρωση, η αποθήκευση, η προσαρμογή ή η
                    μεταβολή, η ανάκτηση, η αναζήτηση πληροφοριών, η χρήση, η
                    κοινολόγηση με διαβίβαση, η διάδοση ή κάθε άλλη μορφή
                    διάθεσης, η συσχέτιση ή ο συνδυασμός, ο περιορισμός, η
                    διαγραφή ή η καταστροφή. Η παρούσα Πολιτική επικαιροποιείται
                    ανά διαστήματα και μπορεί να τροποποιηθεί οποτεδήποτε
                    κρίνεται αναγκαίο, χωρίς προηγούμενη ειδοποίηση, πάντα εντός
                    του εκάστοτε ισχύοντος νομικού πλαισίου και σύμφωνα με τυχόν
                    μεταβολές στην κείμενη νομοθεσία περί προστασίας προσωπικών
                    δεδομένων. Σας προτείνουμε λοιπόν να ελέγχετε την παρούσα
                    Πολιτική ανά τακτά χρονικά διαστήματα ώστε να ενημερώνεστε
                    για τις τυχόν αλλαγές που έχουν πραγματοποιηθεί.
                </p>

                <p>
                    <span class="font-weight-bold d-block">
                        Τί είναι τα προσωπικά δεδομένα;
                    </span>
                    Προσωπικά δεδομένα είναι κάθε πληροφορία που αφορά
                    συγκεκριμένο φυσικό πρόσωπο ή πρόσωπο η ταυτότητα του οποίου
                    μπορεί να εξακριβωθεί (πχ όνομα, αριθμός ταυτότητας,
                    διεύθυνση κτλ). Η εφαρμογή «#LittleGreenFarmers» δεν θα
                    επεξεργαστεί τα προσωπικά σας δεδομένα χωρίς τη συγκατάθεσή
                    σας για σκοπούς άλλους από αυτούς που ορίζονται από τους
                    σκοπούς της εφαρμογής. Ωστόσο, η εφαρμογή
                    «#LittleGreenFarmers» διατηρεί το δικαίωμα, σε εξαιρετικές
                    περιπτώσεις να επεξεργαστεί τα προσωπικά σας στοιχεία στο
                    μέτρο που επιτρέπεται ή απαιτείται από το νόμο, ή/ και από
                    δικαστικές αποφάσεις ή εισαγγελικές παραγγελίες/διατάξεις.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Με ποιον τρόπο συλλέγονται τα προσωπικά δεδομένα;
                    </span>
                    Τα προσωπικά σας δεδομένα μας τα παρέχετε εσείς όταν κάνετε
                    εγγραφή στην εφαρμογή #LittleGreenFarmers ή όταν επιλέγετε
                    να χρησιμοποιήσετε κάποιον αισθητήρα μέτρησης υγρασίας
                    εδάφους σε ένα έργο που δημιουργείτε.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Τι είδους προσωπικά δεδομένα συλλέγονται?
                    </span>
                    Συνοπτικά, τα προσωπικά δεδομένα που συλλέγονται
                    περιλαμβάνουν: <br />- το όνομα, η διεύθυνση ηλεκτρονικού
                    ταχυδρομείου, τα οποία συμπληρώνετε εσείς κατά την εγγραφή
                    σας στην εφαρμογή. Αν και η εφαρμογή απευθύνεται σε παιδιά
                    είναι απαραίτητο η εγγραφή να γίνεται από κάποιον ενήλικα.
                    <br />
                    - την περιοχή στην οποία βρίσκεστε, την οποία παρέχετε όταν
                    συνδέετε κάποιον αισθητήρα μέτρησης υγρασίας εδάφους με την
                    εφαρμογή.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Από ποιες αρχές διέπεται η επεξεργασία των προσωπικών
                        δεδομένων από την #LittleGreenFarmers;
                    </span>
                    Η εφαρμογή «#LittleGreenFarmers» επεξεργάζεται τα προσωπικά
                    σας δεδομένα με τρόπο θεμιτό και νόμιμο για σαφώς
                    καθορισμένους σκοπούς που αναφέρονται στην παρούσα Πολιτική.
                    Τα προσωπικά σας δεδομένα που επεξεργάζεται η εφαρμογή
                    «#LittleGreenFarmers» περιορίζονται στα απολύτως απαραίτητα
                    για την επίτευξη των σκοπών αυτών, είναι ακριβή και
                    επίκαιρα, τηρούνται για χρονικό διάστημα που καθορίζεται από
                    τους σκοπούς της επεξεργασίας, προστατεύονται από επαρκή
                    μέτρα ασφαλείας και δεν διαβιβάζονται σε χώρες που δεν
                    εξασφαλίζουν ικανοποιητικό επίπεδο προστασίας.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Ποιος συλλέγει τα προσωπικά δεδομένα και για ποιόν
                        σκοπό? Διαβιβάζονται σε τρίτους;
                    </span>
                    Τα προσωπικά δεδομένα συλλέγονται και υφίστανται επεξεργασία
                    από τους υπαλλήλους της εταιρείας ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ ,
                    για τους σκοπούς και μόνο της παροχής της εκάστοτε
                    υπηρεσίας. Μετά από εντολή σας, τα προσωπικά σας δεδομένα
                    δύναται να διαβιβαστούν και σε τρίτους. Η ΑΓΡΟΤΙΚΕΣ
                    ΕΦΑΡΜΟΓΕΣ ΙΚΕ δεσμεύεται ότι δεν θα εμπορευθεί τα προσωπικά
                    σας δεδομένα διαθέτοντάς τα προς πώληση/ενοικίαση δίνοντάς
                    τα/ μεταφέροντας /δημοσιοποιώντας ή κοινοποιώντας τα σε
                    τρίτους ή να τα χρησιμοποιήσει με άλλον τρόπο και για άλλους
                    σκοπούς που δύνανται να διακινδυνεύσουν την ιδιωτικότητα, τα
                    δικαιώματα ή τις ελευθερίες σας, εκτός αν επιβάλλεται από το
                    νόμο, δικαστική απόφαση/εντολή, διοικητική πράξη ή αν
                    αποτελεί συμβατική υποχρέωση απαραίτητη για την εύρυθμη
                    λειτουργία της ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ και την
                    πραγματοποίηση των λειτουργιών της.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Για πόσο χρόνο τηρούνται τα προσωπικά μου δεδομένα;
                    </span>
                    Με βάση τις νόμιμες υποχρεώσεις μας και την Πολιτική μας
                    τηρούμε τα προσωπικά σας δεδομένα όσο είναι ενεργός ο
                    προσωπικός σας λογαριασμός.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Επεξεργασία ή διαγραφή λογαριασμού
                    </span>
                    Έχετε το δικαίωμα οποιαδήποτε στιγμή να επεξεργαστείτε τα
                    στοιχεία του λογαριασμού σας, καθώς επίσης σε περίπτωση που
                    δεν επιθυμείτε να χρησιμοποιείτε τις υπηρεσίες του
                    #LittleGreenFarmers, έχετε το δικαίωμα να διαγράψετε το
                    λογαριασμό σας από την εφαρμογή. Η διαγραφή του λογαριασμού
                    σας πραγματοποιείται άμεσα εφόσον αιτηθείτε αυτής μέσω της
                    εφαρμογής #LittleGreenFarmers.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Ποια είναι τα δικαιώματά μου; Τι μπορώ να κάνω αν έχω
                        κάποιο θέμα με την επεξεργασία των προσωπικών μου
                        δεδομένων;
                    </span>
                    Έχετε δικαίωμα ανά πάσα στιγμή να μας ρωτήσετε ποια είναι τα
                    προσωπικά σας δεδομένα τα οποία επεξεργαζόμαστε, για ποιους
                    σκοπούς το κάνουμε, αν τα δίνουμε σε τρίτους και σε ποιους,
                    καθώς και άλλες σχετικές πληροφορίες. Επίσης, έχετε το
                    δικαίωμα να λάβετε δωρεάν αντίγραφο των προσωπικών σας
                    δεδομένων μετά από αίτησή σας. Άλλα δικαιώματα τα οποία
                    έχετε βάσει της σχετικής νομοθεσίας περί προστασίας των
                    προσωπικών δεδομένων περιλαμβάνουν το δικαίωμα να αιτηθείτε
                    την επικαιροποίηση ή/ και διόρθωση των δεδομένων σας, την
                    παύση ή/και περιορισμό της επεξεργασίας τους και τη διαγραφή
                    τους από τα αρχεία της εφαρμογής #LittleGreenFarmers, εφόσον
                    δεν υπάρχει άλλη εκ του νόμου υποχρέωση διατήρησής τους.
                    Επίσης διατηρείτε το δικαίωμα φορητότητας ή/και εναντίωσης
                    στην επεξεργασία των προσωπικών δεδομένων σας. Μπορείτε να
                    ασκήσετε όλα τα ως άνω δικαιώματά σας υποβάλλοντας εγγράφως
                    σχετική αίτηση στη διεύθυνση info@agroapps.gr. Για
                    οποιοδήποτε θέμα έχετε σχετικά με τα προσωπικά σας δεδομένα
                    ή/ και για διευκρινίσεις, μπορείτε να επικοινωνήσετε με τον
                    Υπεύθυνο Προστασίας Δεδομένων (“Data Protection Officer”)
                    της ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ, είτε τηλεφωνικώς στο +30 2310
                    451452, είτε μέσω e-mail, στη διεύθυνση info@agroapps.gr..
                    Σε κάθε περίπτωση, έχετε το δικαίωμα να απευθύνεστε στην
                    αρμόδια Αρχή για την Προστασία των Δεδομένων Προσωπικού
                    Χαρακτήρα (ΑΠΔΠΧ, www.dpa.gr) ή/και να ασκείτε δικαστική
                    προσφυγή. Κάθε αίτημα που υποβάλλεται θα πρέπει να
                    συνοδεύεται από τα κατάλληλα αποδεικτικά στοιχεία
                    ταυτοποίησης και να παρέχονται οι απαιτούμενες πληροφορίες
                    (π.χ. τα δεδομένα που χρειάζονται διόρθωση), όπως
                    περιγράφονται στους όρους χρήσης της εκάστοτε υπηρεσίας. H
                    ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ μπορεί να ζητήσει την παροχή
                    πρόσθετων πληροφοριών αναγκαίων για την επιβεβαίωση της
                    ταυτότητάς σας.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Είναι ασφαλή τα δεδομένα μου;
                    </span>
                    Η ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ θεωρεί άκρως σημαντική την
                    ιδιωτικότητα των προσώπων των οποίων επεξεργάζεται τα
                    προσωπικά τους δεδομένα, είτε μελών της, είτε εθελοντών είτε
                    εργαζομένων, και καταβάλλει κάθε δυνατή προσπάθεια για την
                    προστασία τους, τόσο ως προς την εμπιστευτικότητα/ εχεμύθεια
                    των πληροφοριών, όσο και ως προς την ακεραιότητά τους (να
                    μην αλλοιωθούν, να μην καταστραφούν κατά λάθος κ.λπ.). Η
                    ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ λαμβάνει όλα τα κατάλληλα οργανωτικά
                    και τεχνικά μέτρα, σχεδιασμένα για την προστασία των
                    πληροφοριών από απώλεια, εσφαλμένη χρήση, μη εξουσιοδοτημένη
                    πρόσβαση, αποκάλυψη, διαστρέβλωση ή καταστροφή και φροντίζει
                    για τη θεμιτή και νόμιμη συλλογή και επεξεργασία των
                    δεδομένων προσωπικού χαρακτήρα καθώς και για την ασφαλή
                    τήρησή τους σύμφωνα με τις σχετικές διατάξεις τόσο του
                    ελληνικού όσο και του κοινοτικού και διεθνούς δικαίου
                    σχετικά με την προστασία του ατόμου από την επεξεργασία
                    δεδομένων προσωπικού χαρακτήρα, καθώς και τις αποφάσεις της
                    Αρχής Προστασίας Δεδομένων Προσωπικού Χαρακτήρα,
                    διαφυλάσσοντας το απόρρητο και την εμπιστευτικότητα
                    οποιασδήποτε πληροφορίας περιέρχεται σε γνώση της.
                    Ειδικότερα, η παρούσα Πολιτική λαμβάνει υπόψη της πλήρως τις
                    διατάξεις και τα άρθρα του Κανονισμού (ΕΕ) 2016/679 του
                    Ευρωπαϊκού Κοινοβουλίου για την προστασία των φυσικών
                    προσώπων έναντι της επεξεργασίας των δεδομένων προσωπικού
                    χαρακτήρα και για την ελεύθερη κυκλοφορία των δεδομένων
                    (“General Data Protection Regulation” – “GDPR”) και
                    καταβάλλει συνεχώς κάθε δυνατή προσπάθεια για τη συμμόρφωση
                    με αυτόν.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Τι ισχύει σχετικά με τους συνδέσμους (“links”) σε άλλες
                        ιστοσελίδες;
                    </span>
                    Η εφαρμογή #LittleGreenFarmers ενδέχεται να εμπεριέχει
                    παραπομπές μέσω υπέρ-συνδέσμων (“hyperlinks”) σε άλλες
                    ιστοσελίδες, για το περιεχόμενο και τις υπηρεσίες των οποίων
                    ουδεμία ευθύνη φέρει, ούτε εγγυάται για την διαρκή και
                    ασφαλή προσβασιμότητά τους. Η εφαρμογή #LittleGreenFarmers
                    σε καμία περίπτωση δεν πρέπει να θεωρηθεί ότι αποδέχεται ή
                    υιοθετεί το περιεχόμενο ή τις υπηρεσίες των ιστοσελίδων των
                    υπέρ-συνδέσμων ή ότι συνδέεται με αυτές καθ’ οιονδήποτε
                    τρόπο. Για οποιοδήποτε τυχόν πρόβλημα ανακύψει κατά την
                    χρήση των ως άνω ιστοσελίδων αποκλειστικός υπεύθυνος είναι ο
                    εκάστοτε δικαιούχος της ιστοσελίδας αυτής. Στην περίπτωση
                    των υπέρ-συνδέσμων (“hyperlinks”) προς άλλες ιστοσελίδες, η
                    εφαρμογή #LittleGreenFarmers δεν ευθύνεται για τους όρους
                    διαχείρισης και προστασίας των προσωπικών δεδομένων που
                    αυτοί ακολουθούν.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PrivacyPolicy",
};
</script>
